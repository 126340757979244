import { Button, Input, DatePicker, Form, message } from 'antd';
import React, { useState, useMemo } from 'react';
import DataTable from '../../components/Table/DataTable';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import moment from 'moment';

const { RangePicker } = DatePicker;

function WinLoseByMemberDayHistory() {
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const [form] = Form.useForm();

  const columns = useMemo(() => [
    { Header: 'วันที่', accessor: 'createdAt', Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm:ss') },
    { Header: 'จำนวนได้เสีย', accessor: 'member.name' },
    { Header: 'Username', accessor: 'member.id' },
    { Header: 'Action', accessor: 'member.action' },
  ], []);

  const fetchData = async (queryParams) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/luckywheel/attempt/history`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        params: queryParams,
      });

      console.log(response);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Error fetching history data');
    }
  };

  const updateData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/allwinloseday/fetchbatch`;

    const queryParams = {
      date: moment().format('YYYY-MM-DD')
    };

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        params: queryParams,
      });

      console.log(response);
      message.success('Data updated successfully');
    } catch (error) {
      console.error('Error updating data:', error);
      message.error('Error updating data');
    }
  };
  

  const handleFormSubmit = (values) => {
    const queryParams = {
      startTime: values.dateRange ? values.dateRange[0].toISOString() : undefined,
      endTime: values.dateRange ? values.dateRange[1].toISOString() : undefined,
      memberID: values.memberID || undefined,
      memberName: values.memberName || undefined
    };

    fetchData(queryParams);
  };

  return (
    <div>
      <h1>Win - Lose History</h1>
      <Form
        form={form}
        layout="inline"
        onFinish={handleFormSubmit}
        style={{ marginBottom: '20px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '16px 16px'}}
      >
        <div>
          <Form.Item name="dateRange" label="Date Range">
            <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>

          <Form.Item name="memberID" label="Member ID " style={{marginTop: '10px'}}>
            <Input placeholder="Enter Member ID"/>
          </Form.Item>
        </div>

        <div>
          <Form.Item name="memberName" label="Member Name">
            <Input placeholder="Enter Member Name" />
          </Form.Item>

          <Form.Item name="category" label="By Category     " style={{marginTop: '10px'}}>
            <Input placeholder="Enter Category Name" />
          </Form.Item>
        </div>

        <div>
          <Form.Item name="provider" label="By Provider   ">
            <Input placeholder="Enter Provider Name" />
          </Form.Item>

          <Form.Item name="gameType" label="By GameType" style={{marginTop: '10px'}}>
            <Input placeholder="Enter Type Name" />
          </Form.Item>
        </div>

        <Form.Item style={{marginTop: '10px'}}>
          <Button type="primary" htmlType="submit">
            Fetch History
          </Button>

          <Button type="primary" onClick={updateData} style={{marginLeft: "20px"}}>
            Update Data
          </Button>
        </Form.Item>
      </Form>

      <DataTable columns={columns} data={data} />
    </div>
  );
}

export default WinLoseByMemberDayHistory;
