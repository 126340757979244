import React, { useState } from 'react';
import { useAuth } from '../../AuthContext';
import '../EditableTable.css'; // Import the CSS file for styling

const EditableInfo = ({ info, setInfo }) => {
  const [editingField, setEditingField] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const { auth } = useAuth();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleDoubleClick = (field, value) => {
    setEditingField(field);
    setEditedValue(value);
  };

  const handleInputChange = (event) => {
    setEditedValue(event.target.value);
  };

  const handleBlur = async () => {
    if (info[editingField] === editedValue) {
      setEditingField('');
      return;
    }

    const updatedInfo = { ...info, [editingField]: editedValue };
    setInfo(updatedInfo);

    try {
      const response = await fetch(`${apiUrl}/api/admin/commission/info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(updatedInfo),
      });

      if (!response.ok) {
        throw new Error('Failed to update info');
      }

      console.log('Update successful!');
    } catch (error) {
      console.error('Error updating info:', error);
    }

    setEditingField('');
  };

  return (
    <table className="editable-table">
      <thead>
        <tr>
          <th>Title (EN)</th>
          <th>Description (EN)</th>
          <th>Title (TH)</th>
          <th>Description (TH)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td onDoubleClick={() => handleDoubleClick('titleEn', info.titleEn)}>
            {editingField === 'titleEn' ? (
              <input
                type="text"
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="editable-input"
              />
            ) : (
              info.titleEn
            )}
          </td>
          <td onDoubleClick={() => handleDoubleClick('descriptionEn', info.descriptionEn)}>
            {editingField === 'descriptionEn' ? (
              <textarea
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="editable-textarea"
              />
            ) : (
              info.descriptionEn
            )}
          </td>
          <td onDoubleClick={() => handleDoubleClick('titleTh', info.titleTh)}>
            {editingField === 'titleTh' ? (
              <input
                type="text"
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="editable-input"
              />
            ) : (
              info.titleTh
            )}
          </td>
          <td onDoubleClick={() => handleDoubleClick('descriptionTh', info.descriptionTh)}>
            {editingField === 'descriptionTh' ? (
              <textarea
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="editable-textarea"
              />
            ) : (
              info.descriptionTh
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EditableInfo;
