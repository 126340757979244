import { Layout, theme, Input, DatePicker, notification } from 'antd';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataTable from '../../components/Table/DataTable';
import { useAuth } from '../../AuthContext';
import { formatUTC7 } from '../../utils/dateUtils';

const { Content } = Layout;
const { RangePicker } = DatePicker;

function MoneyAdjustmentIndex() {

  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const { auth } = useAuth();
  const dataRef = useRef(data);

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Wallet ID', accessor: 'walletId' },
    { Header: 'Amount', accessor: 'amount' },
    { Header: 'Balance Before', accessor: 'balanceBefore' },
    { Header: 'Balance After', accessor: 'balanceAfter' },
    { Header: 'Description', accessor: 'description' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Status', accessor: 'status' },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      Cell: ({ value }) => formatUTC7(value),
    },
  ], []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Fetch data once when the component mounts if token is available and dateRange is not set
  useEffect(() => {
    if (auth.token && !dateRange[0] && !dateRange[1]) {
      fetchData();
    }
  }, [auth.token]);

  // Fetch data whenever dateRange is updated and both dates are set
  useEffect(() => {
    if (auth.token && dateRange[0] && dateRange[1]) {
      fetchData();
    }
  }, [auth.token, dateRange]);

  const filteredData = useMemo(() => {
    if (!searchInput) return data;
    return data.filter(
      item =>
        item.memberId.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        item.description.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [data, searchInput]);

  const fetchData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    // Construct query parameters only if dateRange is set
    const queryParams = dateRange[0] && dateRange[1]
      ? new URLSearchParams({
          startDate: dateRange[0].toISOString(),
          endDate: dateRange[1].toISOString(),
        })
      : null;

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/money-adjustments/get${queryParams ? `?${queryParams}` : ''}`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
      dataRef.current = result;
      console.log(result);
    } catch (error) {
      console.error('Error fetching money adjustments:', error);
      notification.error({ message: 'Error', description: 'Failed to fetch money adjustments. Please try again.' });
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  return (
    <Content
      style={{
        padding: 24,
        minHeight: 1000,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <h1>รายการปรับยอดเงิน</h1>

      <RangePicker
        style={{ marginBottom: 20, marginRight: 20 }}
        onChange={handleDateRangeChange}
      />

      <Input
        placeholder="Search Adjustments"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        style={{ marginBottom: 20, width: 300 }}
      />

      <DataTable columns={columns} data={filteredData} />
    </Content>
  );
}

export default MoneyAdjustmentIndex;
