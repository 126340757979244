import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Correct import

export const AuthContext = createContext({
  auth: { token: null, isAuthenticated: false, username: null },
  setAuth: () => {}
});

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('winner-jwt'),
    isAuthenticated: !!localStorage.getItem('winner-jwt'),
    username: localStorage.getItem('username'),
  });

  const login = (token, username) => {
    localStorage.setItem('winner-jwt', token);
    localStorage.setItem('username', username);
    setAuth({ token, isAuthenticated: true, username });
  };

  const logout = () => {
    localStorage.removeItem('winner-jwt');
    localStorage.removeItem('username');
    setAuth({ token: null, isAuthenticated: false, username: null });
  };

  useEffect(() => {
    const token = localStorage.getItem('winner-jwt');
    if (token && isTokenExpired(token)) {
      logout();
    }
  }, [auth.token]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    console.error('useAuth must be used within an AuthProvider');
    return;
  }

  return context;
}

// Define isTokenExpired outside AuthProvider so it can be exported
export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};
