import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification  } from 'antd';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataTable from '../components/Table/DataTable';
import { useAuth } from '../AuthContext';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatUTC7 } from '../utils/dateUtils';

const { Content } = Layout;

function ManageMember() {

  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { auth } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentMember, setCurrentMember] = useState(null);
  const dataRef = useRef(data);

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'memberId' },
    { Header: 'Username', accessor: 'telephoneNumber' },
    { Header: 'ยอดเงินคงเหลือ', accessor: row => row.wallet.balance },
    { Header: 'วันที่สมัคร ', accessor: 'createdAt',
      Cell: ({ value }) => formatUTC7(value) },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'memberId',
      Cell: ({ value }) => (
        <div>
          <EditOutlined onClick={() => handleEdit(value)} style={{ color: 'blue', marginRight: 12, cursor: 'pointer' }} />
          <DeleteOutlined onClick={() => handleDelete(value)} style={{ color: 'red', cursor: 'pointer' }} />
        </div>
      )
    }
  ], []);

  const showModal = (member = null) => {

    if (member) {
        form.setFieldsValue(member);
        setCurrentMember(member);
    } else {
        form.resetFields();
        setCurrentMember(null); // This is a new promotion, not an edit
    }

    setIsModalVisible(true);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const fetchData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${apiUrl}/api/admin/member/all`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result.map(member => ({
            ...member,
            balance: member.wallet.balance
        })));
        dataRef.current = result;

    } catch (error) {
        console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [auth.token]);

  const filteredData = useMemo(() => {
    if (!searchInput) return data;
    return data.filter(
      item =>
        item.memberId.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        item.telephoneNumber.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.password.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [data, searchInput]);

  const handleAction = async (values, isEdit) => {
    const method = isEdit ? "PUT" : "POST";

    var apiUrl;

    if(!isEdit){
      apiUrl = `${process.env.REACT_APP_API_URL}/api/register/store`;
    }else{
      apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/member/update/${currentMember.memberId}`;
    }
    
    const requestOptions = {
      method,
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
      body: JSON.stringify(values)
    };

    console.log(JSON.stringify(isEdit));

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();

      await fetchData();
      notification.success({ message: `Promotion ${isEdit ? 'Updated' : 'Added'}`, description: `The promotion has been successfully ${isEdit ? 'updated' : 'added'}.` });
      form.resetFields();
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error posting member:', error);
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        console.log("currentMember", currentMember);
        handleAction(values, currentMember !== null);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleEdit = (memberId) => {
    var editData = dataRef.current.find(item => item.memberId === memberId);
    showModal(editData);
  };

  const handleDelete = async(memberId) => {
    console.log("Deleting member:", memberId);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/member/delete/${memberId}`;
    const requestOptions = {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" }
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      fetchData();
    } catch (error) {
      console.error('Error deleting promotion:', error);
    }
  };

  return (
    <Content
        style={{
          padding: 24,
          minHeight: 1000,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
      <h1>จัดการสมาชิก</h1>

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Input
          placeholder="Search Members"
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          style={{ marginBottom: 20, width: 300 }}
        />

        <Button type="primary" onClick={() => showModal()} danger className="add-button">
            Add Member
        </Button>
      </div>
      
      <DataTable columns={columns} data={filteredData} />
      
      <Modal title="Register Member" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

        <Form form={form} layout="vertical" 
            initialValues={{
            enable: true
        }}>
          <Form.Item name="telephoneNumber" label="Telephone Number" rules={[{ required: true, message: 'Please enter your telephone number!'  }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
        
    </Content>
  );
}

export default ManageMember;