import { Layout, theme, Input , notification} from 'antd';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataTable from '../components/Table/DataTable';
import { useAuth } from '../AuthContext';
import { LikeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { formatUTC7 } from '../utils/dateUtils';


const { Content } = Layout;

function RequestDeposit() {

    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const { auth} = useAuth();
    const dataRef = useRef(data);

    const columns = useMemo(() => [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Type', accessor: 'type' },
        { Header: 'Amount', accessor: 'amount' },
        { Header: 'Promocode', accessor: 'promotionCode' },
        { Header: 'สถานะ', accessor: 'status' },
        { Header: 'วันที่ฝาก ', accessor: 'createdAt',
        Cell: ({ value }) => formatUTC7(value) },
        { Header: 'แก้ไขล่าสุด ', accessor: 'updatedAt',
            Cell: ({ value }) => formatUTC7(value) },
        {
        Header: 'Actions',
        id: 'actions',
        accessor: row => row.id,
        Cell: ({ row }) => (
            row.original.status === 'waiting' && (
                <div>
                    <LikeOutlined onClick={() => handleApprove(row.original.id)} style={{ color: 'blue', marginRight: 12 }} />
                    <CloseCircleOutlined onClick={() => handleDecline(row.original.id)} style={{ color: 'red' }} />
                </div>
            )
        )
        }
    ], []);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    useEffect(() => {
        fetchData();
    }, [auth.token]);

    const filteredData = useMemo(() => {
        if (!searchInput) return data;
        return data.filter(
        item =>
            item.memberId.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
            item.telephoneNumber.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.password.toLowerCase().includes(searchInput.toLowerCase())
        );
    }, [data, searchInput]);

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${apiUrl}/api/admin/request/deposit/all`, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            setData(result);
            dataRef.current = result;
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

  const handleApprove = async(requestId) => {
    const method = "PUT";
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/request/deposit/approve?requestId=${requestId}`;
    const requestOptions = {
      method,
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      await fetchData();
      notification.success({ message: `Request Approved`, description: `The Request has been successfully approved.` });
    } catch (error) {
      console.error('Error approving request:', error);
    }
  };

  const handleDecline = async(requestId) => {
    const method = "PUT";
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/request/deposit/decline?requestId=${requestId}`;
    const requestOptions = {
      method,
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      await fetchData();
      notification.success({ message: `Request Declined`, description: `The Request has been successfully declined.` });
    } catch (error) {
      console.error('Error declining request:', error);
    }
  };

  return (
    <Content
        style={{
          padding: 24,
          minHeight: 1000,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
      <h1>รายการคำขอฝากเงิน</h1>

      <Input
        placeholder="Search Transactions"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        style={{ marginBottom: 20, width: 300 }}
      />

      <DataTable columns={columns} data={filteredData} />
        
    </Content>
  );
}

export default RequestDeposit;