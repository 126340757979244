import { Button, Input, DatePicker, Form, message } from 'antd';
import React, { useState, useMemo } from 'react';
import DataTable from '../../components/Table/DataTable';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import moment from 'moment';

const { RangePicker } = DatePicker;

function PromotionApplicationHistory() {
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const [form] = Form.useForm();

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Member Name', accessor: 'member.name' }, // Assuming you have member name
    { Header: 'Member ID', accessor: 'member.id' },
    { Header: 'Created At', accessor: 'createdAt', Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm:ss') },
    { Header: 'Prize', accessor: 'luckyWheelPrize.titleEn' }, // Assuming the prize is related
  ], []);

  const fetchData = async (queryParams) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/luckywheel/attempt/history`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${auth.token}`
        },
        params: queryParams,
      });

      console.log(response);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Error fetching history data');
    }
  };

  const handleFormSubmit = (values) => {
    const queryParams = {
      startTime: values.dateRange ? values.dateRange[0].toISOString() : undefined,
      endTime: values.dateRange ? values.dateRange[1].toISOString() : undefined,
      memberID: values.memberID || undefined,
      memberName: values.memberName || undefined
    };

    fetchData(queryParams);
  };

  return (
    <div>
      <h1>Promotion Applications</h1>
      <Form
        form={form}
        layout="inline"
        onFinish={handleFormSubmit}
        style={{ marginBottom: '20px' }}
      >
        <Form.Item name="dateRange" label="Date Range">
          <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>

        <Form.Item name="memberID" label="Member ID">
          <Input placeholder="Enter Member ID" />
        </Form.Item>

        <Form.Item name="memberName" label="Member Name">
          <Input placeholder="Enter Member Name" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Fetch History
          </Button>
        </Form.Item>
      </Form>

      <DataTable columns={columns} data={data} />
    </div>
  );
}

export default PromotionApplicationHistory;
