import React, { useEffect, useState, useRef } from 'react';
import { Input, Button, Modal, Form, message } from 'antd';
import Select from 'react-select'; // Import react-select
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';

function MoneyAdjustment() {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { auth } = useAuth();
  const dataRef = useRef(members);
  const navigate = useNavigate();

  const fetchMembers = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/member/all`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setMembers(
        result.map((member) => ({
          value: member.memberId, // react-select expects 'value' and 'label'
          label: `${member.telephoneNumber} - Balance: ${member.wallet.balance}`, 
        }))
      );
      dataRef.current = result;
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, [auth.token]);

  const handleMemberChange = (selectedOption) => {
    setSelectedMember(selectedOption);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const showConfirmModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    handleSubmit();
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    if (!selectedMember || !amount || !description) {
      message.error('Please fill all fields.');
      return;
    }

    console.log(selectedMember);

    const payload = {
      memberId: selectedMember.value,
      amount: parseFloat(amount),
      description,
    };

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.token}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/money-adjustments/create`, requestOptions);
      if (response.ok) {
        message.success('Money adjustment successful!');
        navigate('/adjustment/index');
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error submitting money adjustment:', error);
      message.error('Money adjustment failed. Please try again.');
    }
  };

  return (
    <div>
      <h1>ปรับยอดเงิน</h1>

      <div className="agent-info-container">
        <div className="agent-info-flex">
          <div className="agent-info-flex-item">
            <Form layout="vertical">
              <Form.Item label="Select Member">
                <Select
                  options={members}
                  placeholder="Select a member"
                  onChange={handleMemberChange}
                  value={selectedMember}
                  isSearchable
                />
              </Form.Item>

              <Form.Item label="Enter Amount">
                <Input
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={handleAmountChange}
                  step="0.01"
                  min="0"
                />
              </Form.Item>

              <Form.Item label="Description">
                <Input
                  placeholder="Enter description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" onClick={showConfirmModal}>
                  Submit
                </Button>
              </Form.Item>
            </Form>

            <Modal
              title="Confirm Adjustment"
              open={isModalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
            >
              <p>Are you sure you want to adjust the amount for this member?</p>
              <p>Member: {selectedMember?.label}</p>
              <p>Amount: {amount}</p>
              <p>Description: {description}</p>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoneyAdjustment;
