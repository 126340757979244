import React, { useState, useEffect } from 'react';
import { Menu, Layout } from 'antd';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  PullRequestOutlined
} from '@ant-design/icons';
import './MainSidebar.css';
import { Link, useLocation } from 'react-router-dom';

const { Sider } = Layout;

function MainSidebar({ collapsed }) {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);

  const getSelectedKey = (pathname) => {
    switch (pathname) {
      case '/dashboard':
        return ['1'];
      case '/member-management':
        return ['2'];
      case '/request/deposit':
        return ['3'];
      case '/report/deposit':
        return ['4'];
      case '/report/withdrawal':
        return ['5'];
      case '/adjustment/index':
        return ['6-1'];
      case '/adjustment/adjust':
        return ['6-2'];
      case '/invitefriend/index':
        return ['7-1'];
      case '/admin-management':
        return ['8'];
      case '/lucky-wheel/index':
        return ['9-1'];
      case '/lucky-wheel/history':
        return ['9-2'];
      case '/promotion/index':
        return ['10-1'];
      case '/promotion/applications':
        return ['10-2'];
      case '/promotion/adjustments':
        return ['10-3'];
      case '/ui-management':
        return ['11'];
      case '/ranking/index':
        return ['12-1'];
      case '/commission/index':
        return ['13-1'];
      case '/win-lose/index':
        return ['14-1'];
      case '/win-lose/history':
        return ['14-2'];
      case '/total-win-lose/index':
        return ['15-1'];
      case '/total-win-lose/history':
        return ['15-2'];
      case '/agent/index':
        return ['16'];
      default:
        return ['1'];
    }
  };

  const menuItems = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: '2',
      icon: <VideoCameraOutlined />,
      label: <Link to="/member-management">จัดการสมาชิก</Link>,
    },
    {
      key: '3',
      icon: <PullRequestOutlined />,
      label: <Link to="/request/deposit">คำขอฝากเงิน</Link>,
    },
    {
      key: '4',
      icon: <UploadOutlined />,
      label: <Link to="/report/deposit">Report ฝากเงิน</Link>,
    },
    {
      key: '5',
      icon: <UploadOutlined />,
      label: <Link to="/report/withdrawal">Report ถอนเงิน</Link>,
    },
    {
      key: '6',
      icon: <UploadOutlined />,
      label: 'ปรับยอดเงิน',
      children: [
        {
          key: '6-1',
          label: <Link to="/adjustment/index">Index</Link>,
        },
        {
          key: '6-2',
          label: <Link to="/adjustment/adjust">ปรับยอด</Link>,
        },
      ],
    },
    {
      key: '7',
      icon: <UploadOutlined />,
      label: 'แนะนำเพื่อน',
      children: [
        {
          key: '7-1',
          label: <Link to="/invitefriend/index">Index</Link>,
        },
      ],
    },
    {
      key: '8',
      icon: <UploadOutlined />,
      label: <Link to="/admin-management">จัดการ Admin</Link>,
    },
    {
      key: '9',
      icon: <UploadOutlined />,
      label: 'Lucky Wheel',
      children: [
        {
          key: '9-1',
          label: <Link to="/lucky-wheel/index">Index</Link>,
        },
        {
          key: '9-2',
          label: <Link to="/lucky-wheel/history">History</Link>,
        },
      ],
    },
    {
      key: '10',
      icon: <UploadOutlined />,
      label: 'โปรโมชั่น',
      children: [
        {
          key: '10-1',
          label: <Link to="/promotion/index">Index</Link>,
        },
        {
          key: '10-2',
          label: <Link to="/promotion/applications">Applications</Link>,
        },
        {
          key: '10-3',
          label: <Link to="/promotion/adjustments">Adjustments</Link>,
        },
      ],
    },
    {
      key: '11',
      icon: <UploadOutlined />,
      label: <Link to="/ui-management">จัดการ UI</Link>,
    },
    {
      key: '12',
      icon: <UploadOutlined />,
      label: 'อันดับ',
      children: [
        {
          key: '12-1',
          label: <Link to="/ranking/index">Index</Link>,
        },
      ],
    },
    {
      key: '13',
      icon: <UploadOutlined />,
      label: 'คอมมิชชั่น',
      children: [
        {
          key: '13-1',
          label: <Link to="/commission/index">Index</Link>,
        },
      ],
    },
    {
      key: '14',
      icon: <UploadOutlined />,
      label: 'Win-Lose Report',
      children: [
        {
          key: '14-1',
          label: <Link to="/win-lose/index">Index</Link>,
        },
        {
          key: '14-2',
          label: <Link to="/win-lose/history">History</Link>,
        },
      ],
    },
    {
      key: '15',
      icon: <UploadOutlined />,
      label: 'Total-Win-Lose Report',
      children: [
        {
          key: '15-1',
          label: <Link to="/total-win-lose/index">Index</Link>,
        },
        {
          key: '15-2',
          label: <Link to="/total-win-lose/history">History</Link>,
        },
      ],
    },
    {
      key: '16',
      icon: <UploadOutlined />,
      label: <Link to="/agent/index">Agent Account</Link>,
    },
  ];

  useEffect(() => {
    const selectedKeys = getSelectedKey(location.pathname);
    const openParentKey = selectedKeys.length > 1 ? selectedKeys[1] : null;
    setOpenKeys(openParentKey ? [openParentKey] : []);
  }, [location.pathname]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="sidebar-logo">
        <img
          src={collapsed ? '/logo60x42.png' : '/long_small_logo.png'}
          alt="logo"
        />
      </div>

      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[getSelectedKey(location.pathname)[0]]}
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
        items={menuItems}
      />
    </Sider>
  );
}

export default MainSidebar;
