import { Button, Input, Modal, Form, Switch, notification } from 'antd';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useAuth } from '../../AuthContext';
import EditableTable from './EditableTable';

function CommissionIndex() {
  const [info, setInfo] = useState(null);
  const { auth } = useAuth();

  const fetchInfo = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth.token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/commission/info`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      setInfo(result);
    } catch (error) {
      console.error('Error fetching info:', error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, [auth.token]);

  if (!info) {
    return <p>Loading...</p>; // Render a loading message while fetching data
  }

  return (
    <div>
      <h2>Commission Info</h2>
      <EditableTable info={info} setInfo={setInfo} />
    </div>
  );
}

export default CommissionIndex;