import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { Button } from 'antd';
import './AgentIndex.css'

function AgentIndex() {

    const [agentData, setAgentData] = useState(null);
    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;

    const refreshData = () => {
        updateAccount();
        fetchAgentData();
    }

    const updateAccount = async () => {
        
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${auth.token}`);
    
        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };
    
        try {
          const response = await fetch(`${apiUrl}/api/admin/agent/update-account`, requestOptions);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }else{
            console.log("Data updated.");
          }
          

        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };


    const fetchAgentData = async () => {
        
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${auth.token}`);
    
        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };
    
        try {
          const response = await fetch(`${apiUrl}/api/admin/agent/get-account`, requestOptions);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
          setAgentData(result);
          console.log(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        updateAccount();
        fetchAgentData();
      }, [auth.token]);

    return (
        <div>
            <h1>Agent Info</h1>

            <div className="agent-info-container">
                <div className="agent-info-flex">
                    <div className="agent-info-flex-item">
                        <h1>Username : {agentData?.username}</h1>
                    </div>

                    <div className="agent-info-flex-item">
                        <h1>Balance : {agentData?.balance}</h1>
                    </div>

                    <div className="agent-info-flex-item">
                        <h1>Status : {agentData?.status}</h1>
                    </div>

                    <div className="agent-info-flex-item">
                        <h1>Update time : {agentData?.updatedAt}</h1>
                    </div>

                    <div className="agent-info-flex-item">
                        <Button onClick={refreshData}>Refresh</Button>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default AgentIndex;
