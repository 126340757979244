import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import './App.css';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ReportDeposit from './pages/ReportDeposit';
import ReportWithdrawal from './pages/ReportWithdrawal';
import MainSidebar from './components/Sidebar/MainSidebar';
import MainHeader from './components/Header/MainHeader';
import ManageMember from './pages/ManageMember';
import MoneyAdjustment from './pages/moneyAdjustment/MoneyAdjustment';
import MoneyAdjustmentIndex from './pages/moneyAdjustment/MoneyAdjustmentIndex';
import InviteFriendIndex from './pages/inviteFriend/InviteFriendIndex';
import ManageAdmin from './pages/ManageAdmin';
import UserInterfaceIndex from './pages/userInterface/Index';
import AgentIndex from './pages/AgentIndex';
import RequestDeposit from './pages/RequestDeposit';

import LuckyWheelIndex from './pages/luckyWheel/LuckyWheelIndex';
import LuckyWheelHistory from './pages/luckyWheel/LuckyWheelHistory';

import PromotionIndex from './pages/promotion/PromotionIndex';

import { AuthProvider, useAuth, isTokenExpired } from './AuthContext'; 
import PromotionApplicationHistory from './pages/promotion/PromotionApplicationHistory';
import PromotionAdjustmentHistory from './pages/promotion/PromotionAdjustmentHistory';

import CommissionIndex from './pages/commission/CommissionIndex';
import RankingIndex from './pages/ranking/RankingIndex';

import WinLoseByMemberDayDashboard from './pages/allWinloseByMemberDay/Index';
import WinLoseByMemberDayHistory from './pages/allWinloseByMemberDay/History';

import TotalWinloseIndex from './pages/totalAllWinloseTurnoverDay/Index';
import TotalWinloseHistory from './pages/totalAllWinloseTurnoverDay/History';

const { Content } = Layout;

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

function AppRoutes() {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!isLoginRoute ? (
        <AppLayout />
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      )}
    </Layout>
  );
}

function AppLayout() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout>
      <MainSidebar collapsed={collapsed} />
      <Layout>
        <MainHeader collapsed={collapsed} toggleCollapsed={() => setCollapsed(!collapsed)} />
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <ProtectedRoutes />
        </Content>
      </Layout>
    </Layout>
  );
}

function ProtectedRoutes() {
  return (
    <Routes>
      <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
      <Route path="/member-management" element={<ProtectedRoute component={ManageMember} />} />
      <Route path="/request/deposit" element={<ProtectedRoute component={RequestDeposit} />} />
      <Route path="/report/deposit" element={<ProtectedRoute component={ReportDeposit} />} />
      <Route path="/report/withdrawal" element={<ProtectedRoute component={ReportWithdrawal} />} />
      
      <Route path="/adjustment/index" element={<ProtectedRoute component={MoneyAdjustmentIndex} />} />
      <Route path="/adjustment/adjust" element={<ProtectedRoute component={MoneyAdjustment} />} />
      
      <Route path="/admin-management" element={<ProtectedRoute component={ManageAdmin} />} />
      
      <Route path="/invitefriend/index" element={<ProtectedRoute component={InviteFriendIndex} />} />
      <Route path="/ranking/index" element={<ProtectedRoute component={RankingIndex} />} />
      <Route path="/commission/index" element={<ProtectedRoute component={CommissionIndex} />} />

      <Route path="/lucky-wheel/index" element={<ProtectedRoute component={LuckyWheelIndex} />} />
      <Route path="/lucky-wheel/history" element={<ProtectedRoute component={LuckyWheelHistory} />} />

      <Route path="/promotion/index" element={<ProtectedRoute component={PromotionIndex} />} />
      <Route path="/promotion/applications" element={<ProtectedRoute component={PromotionApplicationHistory} />} />
      <Route path="/promotion/adjustments" element={<ProtectedRoute component={PromotionAdjustmentHistory} />} />

      <Route path="/win-lose/index" element={<ProtectedRoute component={WinLoseByMemberDayDashboard} />} />
      <Route path="/win-lose/history" element={<ProtectedRoute component={WinLoseByMemberDayHistory} />} />

      <Route path="/total-win-lose/index" element={<ProtectedRoute component={TotalWinloseIndex} />} />
      <Route path="/total-win-lose/history" element={<ProtectedRoute component={TotalWinloseHistory} />} />
      
      <Route path="/ui-management" element={<ProtectedRoute component={UserInterfaceIndex} />} />
      <Route path="/agent/index" element={<ProtectedRoute component={AgentIndex} />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}

function ProtectedRoute({ component: Component }) {
  const { auth, logout } = useAuth();
  const navigate = useNavigate(); // Now it's safe to use useNavigate

  useEffect(() => {
    const token = localStorage.getItem('winner-jwt');
    if (token && auth.token && isTokenExpired(token)) {
      logout(); // Remove token and set auth state to null
      navigate('/login'); // Redirect to login page
    }
  }, [auth.token, navigate, logout]);

  return auth.isAuthenticated ? <Component /> : <Navigate to="/login" />;
}

export default App;
