import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification  } from 'antd';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataTable from '../../components/Table/DataTable';
import { useAuth } from '../../AuthContext';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatUTC7 } from '../../utils/dateUtils';


const { Content } = Layout;

function PromotionIndex() {

  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPromotion, setCurrentPromotion] = useState(null);
  const { auth } = useAuth();
  const [form] = Form.useForm();
  const dataRef = useRef(data);

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Long Name', accessor: 'longName' },
    { Header: 'Description', accessor: 'description' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Multiplier', accessor: 'multiplier' },
    { Header: 'Create Date', accessor: 'createdAt', Cell: ({ value }) => formatUTC7(value) },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'id',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          <EditOutlined onClick={() => handleEdit(value)} style={{ color: 'blue', marginRight: 12, cursor: 'pointer' }} />
          <DeleteOutlined onClick={() => handleDelete(value)} style={{ color: 'red', cursor: 'pointer' }} />
        </div>
      )
    }
  ], []);
  
  useEffect(() => {
    fetchPromotions();
  }, [auth.token]);

  const fetchPromotions = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/promotion/all`;
    const requestOptions = {
        method: "GET",
        headers: { "Authorization": `Bearer ${auth.token}` },
        redirect: "follow"
    };

    try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
        dataRef.current = result;
    } catch (error) {
        console.error('Error fetching promotions:', error);
    }
  };

  useEffect(() => {
    console.log("Data after fetching:", data);
  }, [data]);

  const showModal = (promotion = null) => {
    
    console.log("promotion in showModal = ", promotion);
    if (promotion) {
      form.setFieldsValue(promotion);
      setCurrentPromotion(promotion);
    } else {
        form.resetFields();
        setCurrentPromotion(null); // This is a new promotion, not an edit
    }

    setIsModalVisible(true);

  };

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        console.log("currentPromotion", currentPromotion);
        handleAction(values, currentPromotion !== null);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const filteredData = useMemo(() => {
    if (!searchInput) return data;
    return data.filter(
      item =>
        item.name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        item.type.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.description.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [data, searchInput]);

  const handleEdit = (promotionId) => {
    var editData = dataRef.current.find(item => item.id === promotionId);
    showModal(editData);
  };

  const handleDelete = async(promotionId) => {
    console.log("Deleting promotion:", promotionId);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/promotion/delete/${promotionId}`;
    const requestOptions = {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" }
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      fetchPromotions();
    } catch (error) {
      console.error('Error deleting promotion:', error);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleAction = async (values, isEdit) => {
    const method = isEdit ? "PUT" : "POST";
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/promotion/${isEdit ? `update/${currentPromotion.id}` : 'add'}`;
    const requestOptions = {
      method,
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
      body: JSON.stringify(values)
    };

    console.log(JSON.stringify(values));

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();

      await fetchPromotions();
      notification.success({ message: `Promotion ${isEdit ? 'Updated' : 'Added'}`, description: `The promotion has been successfully ${isEdit ? 'updated' : 'added'}.` });
      form.resetFields();
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error posting promotion:', error);
    }
  };

  return (
    <Content
        style={{
          padding: 24,
          minHeight: 1000,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
      <h1>โปรโมชั่น</h1>
      
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Input
          placeholder="Search Promotion"
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          style={{ marginBottom: 20, width: 300 }}
        />

        <Button type="primary" onClick={() => showModal()} danger className="add-button">
            Add Promotion
        </Button>
      </div>

      <DataTable columns={columns} data={filteredData}/>
      
      <Modal title="Add Promotion" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical" 
          initialValues={{
          enable: true
        }}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="longName" label="Long Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="promotionCode" label="Promotion Code" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: 'Please select the promotion type!' }]}
          >
            <Select placeholder="Select a promotion type">
              <Select.Option value="free">Free Promotion</Select.Option>
              <Select.Option value="deposit">Deposit Promotion</Select.Option>
              <Select.Option value="other">Other Promotion</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="multiplier" label="Multiplier" rules={[{ required: true }]}>
            <Input type="number" step="0.01" />
          </Form.Item>
          <Form.Item name="enable" label="Enable" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="imgUrlVertical" label="Vertical Image URL">
            <Input />
          </Form.Item>
          <Form.Item name="imgUrlHorizontal" label="Horizontal Image URL">
            <Input />
          </Form.Item>
          <Form.Item name="imgUrlMobile" label="Mobile Image URL">
            <Input />
          </Form.Item>
        </Form>
      </Modal>

    </Content>
  );
}

export default PromotionIndex;