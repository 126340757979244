import { Button, Input, Modal, Form, Switch, notification } from 'antd';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import DataTable from '../../components/Table/DataTable';
import { useAuth } from '../../AuthContext';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import EditableTable from './EditableTable';
import axios from 'axios';

function LuckyWheelIndex() {
  const [info, setInfo] = useState(null);
  const [luckyWheelPrizes, setLuckyWheelPrizes] = useState([]);
  const [isPrizeModalVisible, setIsPrizeModalVisible] = useState(false);
  const [currentPrize, setCurrentPrize] = useState(null);
  const { auth } = useAuth();
  const [form] = Form.useForm();
  const prizeRef = useRef(luckyWheelPrizes);
  const { confirm } = Modal;

  const columns = useMemo(() => [
    { Header: 'Title EN', accessor: 'titleEn' },
    { Header: 'Title TH', accessor: 'titleTh' },
    { Header: 'description EN', accessor: 'descriptionEn' },
    { Header: 'description TH', accessor: 'descriptionTh' },
    { 
      Header: 'Chance', 
      accessor: 'chance', 
      Cell: ({ value }) => {
        if (value === 0) return '0.00'; // Handle exact zero
    
        if (value < 0.01) {
          return value.toPrecision(1); // Use scientific notation or precision for very small numbers
        }
    
        return value.toFixed(2); // Format larger numbers with 2 decimal places
      }
    },
    { 
      Header: 'Enabled', 
      accessor: 'enabled', 
      Cell: ({ value }) => {
        return value ? 'Yes' : 'No';  // Display Yes/No for true/false values
      } 
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'id',
      Cell: ({ value }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <EditOutlined onClick={() => handleEdit(value)} style={{ color: 'blue', marginRight: 12, cursor: 'pointer' }} />
            <DeleteOutlined onClick={() => handleDelete(value)} style={{ color: 'red', cursor: 'pointer' }} />
          </div>
        );
      }
    }
  ], [luckyWheelPrizes]);

  const fetchInfo = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth.token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/luckywheel/info`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      setInfo(result); // Set the single info object
    } catch (error) {
      console.error('Error fetching info:', error);
    }
  };

  const fetchPrizes = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth.token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/luckywheel/prizes `, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setLuckyWheelPrizes(result);
      prizeRef.current = result;
    } catch (error) {
      console.error('Error fetching prizes:', error);
    }
  };

  useEffect(() => {
    fetchInfo();
    fetchPrizes();
  }, [auth.token]);

  const showModal = (prize = null) => {
    
    if (prize) {
      form.setFieldsValue(prize);
      setCurrentPrize(prize);
    } else {
      form.resetFields();
      setCurrentPrize(null); // This is a new promotion, not an edit
    }

    setIsPrizeModalVisible(true);
  };

  const handleEdit = (prizeId) => {
    var editData = prizeRef.current.find(item => item.id === prizeId);
    showModal(editData);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        console.log("currentPrize", currentPrize);
        handleAction(values, currentPrize !== null);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsPrizeModalVisible(false);
  };

  const handleDelete = (prizeId) => {
    confirm({
      title: 'Are you sure you want to delete this prize?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/luckywheel/prizes/delete/${prizeId}`;
        const requestOptions = {
          method: "DELETE",
          headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" }
        };
  
        try {
          const response = await fetch(apiUrl, requestOptions);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          await fetchPrizes(); // Refresh the prizes list
          notification.success({
            message: 'Prize Deleted',
            description: 'The prize has been successfully deleted.'
          });
        } catch (error) {
          notification.error({
            message: 'Delete Failed',
            description: 'There was an issue deleting the prize. Please try again.'
          });
          console.error('Error deleting prize:', error);
        }
      },
      onCancel() {
        console.log('Cancel delete');
      },
    });
  };
  

  const handleAction = async (values, isEdit) => {
    const method = isEdit ? "PUT" : "POST";
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/luckywheel/prizes/${isEdit ? `update/${currentPrize.id}` : 'add'}`;
    const requestOptions = {
      method,
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
      body: JSON.stringify(values)
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();

      await fetchPrizes();
      notification.success({ message: `Prize ${isEdit ? 'Updated' : 'Added'}`, description: `The prize has been successfully ${isEdit ? 'updated' : 'added'}.` });
      form.resetFields();
      setIsPrizeModalVisible(false);
    } catch (error) {
      console.error('Error posting prize:', error);
    }
  };

  if (!info) {
    return <p>Loading...</p>; // Render a loading message while fetching data
  }

  return (
    <div>
      <h1>Lucky Wheel Prize</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => showModal()} type="primary" danger style={{marginBottom: "20px"}}>
          Add Prize
        </Button>
      </div>

      <DataTable columns={columns} data={luckyWheelPrizes} />

      <h2>Lucky Wheel Info</h2>
      <EditableTable info={info} setInfo={setInfo} />

      <Modal title="Add Promotion" open={isPrizeModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical" 
          initialValues={{
          enable: true
        }}>
          <Form.Item name="titleTh" label="ชื่อภาษาไทย" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="titleEn" label="ชื่อภาษาอังกฤษ" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="descriptionTh" label="รายละเอียดภาษาไทย" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="descriptionEn" label="รายละเอียดภาษาอังกฤษ" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="chance" label="โอกาสได้" rules={[{ required: true }]}>
            <Input type="number" step="0.01" />
          </Form.Item>
          <Form.Item name="enabled" label="Enable" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item name="imageUrl" label="Image URL">
            <Input />
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
}

export default LuckyWheelIndex;