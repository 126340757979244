import { Button, Input, Modal, Form, Select, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../../components/Table/DataTable';
import { useAuth } from '../../AuthContext';
import { formatUTC7 } from '../../utils/dateUtils';
import { SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

function UserInterfaceIndex() {
  const [data, setData] = useState([]);
  const [imageSettingsData, setImageSettingsData] = useState([]); // State for image settings data
  const [searchInput, setSearchInput] = useState('');
  const { auth } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [selectedType, setSelectedType] = useState(''); // Track selected type

  const isImageUsedInSettings = (uploadImageId) => {
    return imageSettingsData.some(setting => setting.uploadImage && setting.uploadImage.id === uploadImageId);
  };

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Original Name', accessor: 'originalName' },
    { Header: 'URL', accessor: 'url', Cell: ({ value }) => {return `${process.env.REACT_APP_API_URL}${value}`;} },
    { Header: 'Device', accessor: 'device' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Theme', accessor: 'theme' },
    { Header: 'Uploaded Date', accessor: 'createdAt', Cell: ({ value }) => formatUTC7(value) },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'id',
      Cell: ({ row: { original } }) => {
        const isUsed = isImageUsedInSettings(original.id);
  
        return (
          <div style={{display:"flex", justifyContent:"center"}}>
            <DeleteOutlined onClick={() => handleDelete(original.id)} style={{ color: 'red', cursor: 'pointer' }} />
            {!isUsed && original.type !== 'promotion' && (
              <SettingOutlined onClick={() => makeMainImage(original.id)} style={{ color: 'blue', cursor: 'pointer', marginLeft: 10 }} />
            )}
          </div>
        );
      }
    }
  ], [imageSettingsData]);

  const imageSettingsColumns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Device', accessor: 'device' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Theme', accessor: 'theme' },
    { Header: 'Width', accessor: 'width' },
    { Header: 'Height', accessor: 'height' },
  ], []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      handleUpload(values);
    });
  };

  const handleDelete = async (id) => {
    console.log("Deleting UploadImage :", id);
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/image/delete/${id}`;
    const requestOptions = {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" }
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      fetchData();
      message.success("Image deleted.");
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFile(null); // Clear the file input
    setSelectedType(''); // Reset the selected type
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (values) => {
    if (!file || !values.type) {
      message.error('Please provide all required fields');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('device', values.device);
    formData.append('type', values.type);
    formData.append('theme', values.theme);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/image/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth.token}`, // Adding token to the request headers
          },
        }
      );
      console.log(response);
      message.success(response.data);
      setIsModalVisible(false); // Close modal on success
      fetchData(); // Refresh the data table
    } catch (error) {
      message.error('Upload failed');
    }
  };

  const makeMainImage = async (imageId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/image-setting/set-main`, {
        imageId,
      }, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      message.success('Image set as main successfully');
      fetchImageSettings(); // Refresh image settings after updating
    } catch (error) {
      message.error('Failed to set as main image');
    }
  };

  const fetchData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth.token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/image/get/all`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchImageSettings = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth.token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    try {
      const response = await fetch(`${apiUrl}/api/admin/image-setting/get/all`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setImageSettingsData(result); // Set image settings data
    } catch (error) {
      console.error('Error fetching image settings:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchImageSettings(); // Fetch image settings data
  }, [auth.token]);

  const filteredData = useMemo(() => {
    if (!searchInput) return data;
    return data.filter((item) =>
      item.id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      item.url.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.originalName.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.type.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.device.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [data, searchInput]);

  return (
    <div>
      <h1>Manage Images</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Input
          placeholder="Search Images"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{ marginBottom: 20, width: 300 }}
        />

        <Button type="primary" onClick={showModal} danger>
          Upload Image
        </Button>
      </div>

      <DataTable columns={columns} data={filteredData} />

      <h2>Image Settings</h2>
      <DataTable columns={imageSettingsColumns} data={imageSettingsData} style={{marginBottom: "50px"}}/>

      <Modal
        title="Upload New Image"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ enable: true }}
        >
          <Form.Item
            name="file"
            label="Select Image"
            valuePropName="file"
            rules={[{ required: true, message: 'Please select an image' }]}
          >
            <Input type="file" onChange={handleFileChange} />
          </Form.Item>

          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: 'Please select a type' }]}
          >
            <Select placeholder="Select Type" onChange={(value) => setSelectedType(value)}>
              <Option value="main_background">Main Background</Option>
              <Option value="menu_background">Menu Background</Option>
              <Option value="promotion">Promotion</Option>
            </Select>
          </Form.Item>

          {/* Conditionally render device and theme only if type is not "promotion" */}
          {selectedType !== 'promotion' && (
            <>
              <Form.Item
                name="device"
                label="Device"
                rules={[{ message: 'Please select a device' }]}
              >
                <Select placeholder="Select Device">
                  <Option value="desktop">Desktop</Option>
                  <Option value="mobile">Mobile</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="theme"
                label="Theme"
                rules={[{ message: 'Please select a theme' }]}
              >
                <Select placeholder="Select Theme">
                  <Option value="dark">Dark</Option>
                  <Option value="light">Light</Option>
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
}

export default UserInterfaceIndex;
