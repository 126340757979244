import React, { useState } from 'react';
import { useAuth } from '../../AuthContext';
import '../EditableTable.css'; // Import the CSS file for styling

const EditableTableAmount = ({ info, setInfo }) => {
  const [editingField, setEditingField] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const { auth } = useAuth();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleDoubleClick = (field, value) => {
    setEditingField(field);
    setEditedValue(value);
  };

  const handleInputChange = (event) => {
    setEditedValue(event.target.value);
  };

  const handleBlur = async () => {
    if (info[editingField] === editedValue) {
      setEditingField('');
      return;
    }

    const updatedInfo = { ...info, [editingField]: editedValue };
    setInfo(updatedInfo);

    try {
      const response = await fetch(`${apiUrl}/api/admin/ranking/amount`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(updatedInfo),
      });

      if (!response.ok) {
        throw new Error('Failed to update info');
      }

      console.log('Update successful!');
    } catch (error) {
      console.error('Error updating info:', error);
    }

    setEditingField('');
  };

  return (
    <table className="editable-table">
      <thead>
        <tr>
          <th>ยอดฝาก Silver Rank</th>
          <th>ยอดฝาก Gold Rank</th>
          <th>ยอดฝาก Platinum Rank</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td onDoubleClick={() => handleDoubleClick('silverRankAmount', info.silverRankAmount)}>
            {editingField === 'silverRankAmount' ? (
              <input
                type="text"
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="editable-input"
              />
            ) : (
              info.silverRankAmount
            )}
          </td>
          <td onDoubleClick={() => handleDoubleClick('goldRankAmount', info.goldRankAmount)}>
            {editingField === 'goldRankAmount' ? (
              <textarea
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="editable-textarea"
              />
            ) : (
              info.goldRankAmount
            )}
          </td>
          <td onDoubleClick={() => handleDoubleClick('platinumRankAmount', info.platinumRankAmount)}>
            {editingField === 'platinumRankAmount' ? (
              <input
                type="text"
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoFocus
                className="editable-input"
              />
            ) : (
              info.platinumRankAmount
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EditableTableAmount;
